const Constants = {
  siteMetadata: {
    defaultTitle: "Square Electrical",
    defaultDescription: "Square Electrical guarantees delivering high quality electrical services to your home and business. We serve Virginia, Washington DC, and Maryland.",
    defaultImage: "/images/logo.png",
    titleTemplate: "Square Electrical | Serving Virginia, Washington DC, and Maryland",
    siteUrl: "https://freemarylandwebdesign.com",
    phone: "(202) 596-9558",
    email: "contact@squareelectrical.com"
  },
  footers: [],
  testimonials: [
    {
      name: "Stephen Njuguna",
      company: "",
      quote: "\"This company is run on a very high level. They installed all my outlets and completely overhauled my electric fence. They also repaired my fans. I will definitely recommend this company to everyone.\""
    },
    {
      name: "Hector Delcallar",
      company: "",
      quote: "\"I had them upgrade my electrical panel in my home and I was very pleased with the service. The technician conducted himself in a very professional manner and was very knowledgeable recommending the most suitable upgrade I needed for my home.\""
    },
    {
      name: "Bridgett Hutchinson",
      company: "",
      quote: "\"I hired Square Electrical to fix an electrical problem in my home and I was completely satisfied with their work and professionalism.\""
    }
  ],
  pricingTiers: []
}

export default Constants