import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Hidden from "@material-ui/core/Hidden"
import Drawer from "@material-ui/core/Drawer"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import List from "@material-ui/core/List"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import HomeIcon from "@material-ui/icons/Home"
import CallIcon from "@material-ui/icons/Call"
import InfoIcon from "@material-ui/icons/Info"
import Link from "@material-ui/core/Link"

import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"

import Constants from "../config/constants"

import { Link as GatsbyLink } from "gatsby"
import { PhoneIphone } from "@material-ui/icons"

const styles = makeStyles(theme => {
  return {
    tab: {
      padding: "20px 12px"
    },
    title: {
      marginRight: theme.spacing(1)
    },
    list: {
      width: 250
    },
    call: {
      position: "absolute",
      right: 20,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12
      }
    },
    text: {
      verticalAlign: "middle"
    },
    menu: {
      backgroundColor: theme.palette.primary.main
    },
    drawer: {
      backgroundColor: theme.palette.primary.main
    }
  }
})

const NavBar = (props) => {

  const [open, setOpen] = useState(false)

  const classes = styles()

  const toggleDrawer = (open) => {
    setOpen(open)
  }

  const getPath = () => {
    return typeof window !== "undefined" ? window.location.pathname : ""
  }

  const doesRouteMatch = (routes) => {
    return routes.includes(getPath())
  }

  return (
    <div>
      <AppBar position="static" elevation={0}>
        <Toolbar className={classes.toolbar}>
          <Hidden mdUp>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => {
                toggleDrawer(true)
              }}
            >
              <MenuIcon/>
            </IconButton>
          </Hidden>
          <Hidden smDown>
            <Tabs value={getPath()} className={classes.tabs}>
              <Tab label="Home" value='/' to='/'
                   className={classes.tab} component={GatsbyLink}/>
              <Tab label="Contact" value='/contact' to='/contact'
                   className={classes.tab} component={GatsbyLink}/>
              <Tab label="About" value='/about' to='/about'
                   className={classes.tab} component={GatsbyLink}/>
            </Tabs>
          </Hidden>

          <div className={classes.call}>
            <Typography variant="overline">
              <div className={classes.text}>
                <Hidden smDown>Call us for a free consultation: </Hidden>
                <Hidden mdUp>
                  <PhoneIphone className={classes.text}/>
                </Hidden>
                <Link
                  href={`tel:${Constants.siteMetadata.phone}`}
                  variant="body2"
                  color="secondary"
                >
                  {Constants.siteMetadata.phone}
                </Link>
              </div>
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <Hidden mdUp>
        <Drawer
          anchor="right"
          open={open}
          onClose={() => {
            toggleDrawer(false)
          }}
          classes={{ paper: classes.drawer }}
        >
          <div role="presentation" className={classes.list}>
            <List>
              <ListItem
                button
                component={GatsbyLink}
                to="/"
                onClick={() => {
                  toggleDrawer(false)
                }}
                selected={doesRouteMatch(["/"])}
              >
                <ListItemIcon>
                  <HomeIcon/>
                </ListItemIcon>
                <ListItemText primary="Home"/>
              </ListItem>
              <ListItem
                button
                component={GatsbyLink}
                to="/contact"
                onClick={() => {
                  toggleDrawer(false)
                }}
                selected={doesRouteMatch([`/contact`])}
              >
                <ListItemIcon>
                  <CallIcon/>
                </ListItemIcon>
                <ListItemText primary="Contact Us"/>
              </ListItem>
              <ListItem
                button
                component={GatsbyLink}
                to="/about"
                onClick={() => {
                  toggleDrawer(false)
                }}
                selected={doesRouteMatch([`/about`])}
              >
                <ListItemIcon>
                  <InfoIcon/>
                </ListItemIcon>
                <ListItemText primary="About"/>
              </ListItem>
            </List>
          </div>
        </Drawer>
      </Hidden>
    </div>
  )
}

export default NavBar
