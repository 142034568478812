import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Navigation from "./navigation"
import Container from "@material-ui/core/Container"
import { Typography } from "@material-ui/core"
import CssBaseline from "@material-ui/core/CssBaseline"
import Footer from "./footer"
import Triangle from "./shared/Triangle"

const styles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2)
  }
}))

const Layout = ({ header, children }) => {
  const classes = styles()
  return (
    <>
      <Background/>
      <div style={{ position: "relative", height: "100%" }}>

        <CssBaseline/>
        <Navigation/>
        {header && <Container maxWidth="lg" className={classes.container}>
          <Typography variant="h4">
            {header}
          </Typography>
        </Container>}
        {children}
        <Footer/>
      </div>
    </>
  )
}

const Background = () => (
  <>
    <Triangle
      color={"#5887ae"}
      height={["35vh", "30vh"]}
      width={["95vw", "40vh"]}
    />

    <Triangle
      color={"#16496a"}
      height={["38vh", "30vh"]}
      width={["50vw", "30vh"]}
    />
  </>
)

export default Layout
