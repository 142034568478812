import styled from "styled-components"

const Triangle = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  z-index: -2;
  ${({ color, height, width, position = "top-left" }) => {
  const [y, x] = position.split("-")
  return `
      border-${y}: ${height[0]} solid ${color};
      ${y}: 0;
      border-${invertX(x)}: ${width[0]} solid transparent;
      ${x}: 0;
    `
}}
  @media only screen and (min-width: 768px) {
    ${({ height, width, position = "top-left" }) => {
  const [y, x] = position.split("-")
  return `
        border-${y}-width: ${height[1]};
        border-${invertX(x)}-width: ${width[1]};
      `
}}
  }
`

const invertX = (x) => (x === "right" ? "left" : "right")

export default Triangle
